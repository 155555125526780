body {
    background-color: #ffffff;
    margin: 0;
}

div .page-root {
    margin-left: 0;
    margin-right: 0;
}

div .page-header {
    background-color: #fcfcfc;
    border-bottom: 2px solid #e8e8e8;
    padding: 0.5em;
}

div .page-footer {
    color: #ffffff;
    background-color: #808080;
}

div .page-content {
    background-color: #ffffff;
    padding: 0.5em;
    padding-bottom: 2.5em;
}

.condition-default {
    color: #808080;
}

.condition-error {
    color: #ff8000;
}

.condition-warning {
    color: #ffc000;
}

.condition-ok {
    color: #00b000;
}

.notification-default {
    color: #808080;
}

.notification-error {
    color: #ff0000;
}

.notification-warning {
    color: #ffc000;
}

.notification-ok {
    color: #00b000;
}

.notification-info {
    color: #2569cf;
}

hr {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #d0d0d0;
}